import React from "react"

function Callout(props) {
  const { residentialTypes } = props
  return (
    <div className="callout">
      <div className="callout__wrapper">
        {residentialTypes.map(type => (
          <div key={type.residential_types_headline} className="callout__container">
            <img className="callout__image" src={type.residential_types_image.localFile.childImageSharp.fluid.src} alt={type.residential_types_headline} />
            <h4 className="callout__title">{type.residential_types_headline}</h4>
            <p className="callout__copy">{type.residential_types_copy}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export { Callout }
