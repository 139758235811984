import React from "react"
import { graphql } from "gatsby"

// Utility Functions
import { handleMenuItems } from "../utils/handleMenuItems"

// Custom components
import { Nav } from "../components/Nav"
import { Header } from "../components/Header"
import { Callout } from "../components/Callout"
import { Row } from "../components/Row"
import { Footer } from "../components/Footer"

// Residential page template
export default function TemplateResidential(props) {
  const {
    data: {
      wordpressPage: {
        slug,
        acf: {
          residential_headline: residentialHeadline,
          residential_hero_image: residentialHeroImage,
          residential_hero_headline: residentialHeroHeadline,
          residential_hero_copy: residentialHeroCopy,
          residential_types: residentialTypes,
          residential_featured_projects: residentialFeaturedProjects,
          residential_featured_projects_headline: residentialFeaturedProjectsHeadline,
        },
      },
      wordpressAcfOptions: {
        options: {
          sitewide_company_information_company_name: companyName,
          sitewide_company_information_company_twitter: companyTwitter,
          sitewide_company_information_company_linkedin: companyLinkedIn,
        },
      },
      allWordpressPage: { edges },
    },
  } = props

  return (
    <section className="white residential">
      <Nav slug={slug} menuItemsSorted={handleMenuItems({ items: edges })} />

      <Header headerHeadline={residentialHeadline}>
        <div className="feature">
          <div className="feature__container">
            <img className="feature__image" src={residentialHeroImage.localFile.childImageSharp.fluid.src} alt="Robert Idol Residential Design" />
          </div>
          <div className="feature__container feature__container--alt">
            <h3 className="feature__headline">{residentialHeroHeadline}</h3>
            <p className="feature__copy">{residentialHeroCopy}</p>
          </div>
        </div>
      </Header>

      <Callout residentialTypes={residentialTypes} />

      <Row rowPage="residential" featuredProjs={residentialFeaturedProjects} headline={residentialFeaturedProjectsHeadline} />

      <Footer
        companyName={companyName}
        companyTwitter={companyTwitter}
        companyLinkedIn={companyLinkedIn}
        slug={slug}
        menuItemsSorted={handleMenuItems({ items: edges })}
      />
    </section>
  )
}

export const pageQuery = graphql`
  query {
    wordpressPage(title: { eq: "Residential" }) {
      slug
      acf {
        residential_headline
        residential_hero_image {
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
        residential_hero_headline
        residential_hero_copy
        residential_types {
          residential_types_image {
            localFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          residential_types_headline
          residential_types_copy
        }
        residential_featured_projects_headline
        residential_featured_projects {
          residential_featured_projects_image {
            localFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          residential_featured_projects_name
          residential_featured_projects_type
          residential_featured_projects_page_link
        }
      }
    }
    wordpressAcfOptions {
      options {
        sitewide_company_information_company_name
        sitewide_company_information_company_twitter
        sitewide_company_information_company_linkedin
      }
    }
    allWordpressPage(filter: { wordpress_parent: { eq: 0 } }) {
      edges {
        node {
          title
          menu_order
          slug
        }
      }
    }
  }
`
